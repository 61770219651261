.add__supItem{
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.add__supItem input{
    margin-bottom: 10px;
}

h3,
p{
    color: var(--baseFont);
}

.support__data{
    margin: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.header__addBtn{
    width: 100%;
    display: flex;
    align-items:  center ;
    gap: 25px;
    margin-bottom: 10px;
}

.header__addBtn input{
    width: 90%;
}


.support_block p{
    background-color: rgba(115, 63, 38, 0.25);
    font-size: 18px;
    padding: 5px 10px;
    margin: 10px 10px 10px 0;
    text-align: justify;
    border-radius: 10px;
}

.highlight{
    color: var(--blueCircle);
}