.archive{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.archive input{
    width: 90%;
}

.page__container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 80vh;
    overflow-y: scroll;
    min-width: 1600px;
}

.pageEmpty__container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 80vh;
    min-width: 1600px;
}

.custom__table {
    width: 98%;
    text-align: center;
    height: fit-content;
}

.custom__table th, .custom__table td {
    width: auto;
    max-width: none;
    padding: 3px 5px;
    font-size: 15px;
    color: var(--baseFont);
}

.custom__table td{
    background-color: var(--baseBackground);
    color: var(--baseFont);
}

.custom__table td img,
.custom__table th img{
    width: 30px;
    cursor: pointer;
}

.line:hover{
    opacity: 0.8;
    cursor: pointer;
}
