button{
    cursor: pointer;
    transition: all .3s ease-in;
    background-color: transparent;
    border: 1px solid transparent;
}

button:not(:disabled):hover{
	background-color: var(--baseBackground);
	color: var(--baseFont);
	cursor: pointer;
}

.customBtn{
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: var(--inputBg);
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
    text-align: center;
    font-size: 18px;
    padding: 10px 15px;
}

.customBtn.disabled{
    color: var(--disabled);
    pointer-events: none; 
    color: #bbb7b7;
    border: 1px solid transparent;
    box-shadow: none;
}

.customBtn:hover{
    background-color: var(--baseBackground);
}

.customBtn:active{
    background-color:var(--baseFont);
    color: white;
    border: 1px solid transparent;
}

.customBtn:active svg path{
    fill: white;
}

.customLink{
    color: var(--baseFont);
    border-radius: 10px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: var(--inputBg);
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
    text-align: center;
    font-size: 15px;
    padding: 5px 15px;
}

.customLink:hover{
    background-color: var(--baseBackground);
}

.calc{
    background: #63a949;
}

.clean{
    background: #d15c50;
}

.calc,
.clean{
    width: 200px;
    color: white;
    border-radius: 10px;
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    text-align: center;

}

.scroll-to-top-button {
    background-color: var(--baseFont);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 75px;
    height: 70px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    outline: none;
    transition: background-color 0.3s ease;
}
