.name__match{
    display: flex;
}

.matchpage{
    display: flex;
    align-items: center;
    background-image: url('/public/img/png/fon_l.jpg');
    border-radius: 20px;
    padding: 20px;
    box-shadow: inset 0 0 7px 3px #733f26;
    min-width: 1800px;
    flex-direction: column;
}

.matchHeader{
    display: flex;
    align-items: flex-start;
    gap: 25px;
}

.matchCircles{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 50px;
}

.blockSmall{
    display: flex;
    justify-content: space-between;
}

.blockSimple{
    display: flex;
    justify-content: space-between;
}

.block{
    display: flex;
    justify-content: space-between;
    min-width: 2300px;
}

.matchItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page{
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
}

.match{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.match p{
    color: var(--baseFont);
    font-size: 18px;    
}

.match input,
.match select {
    width: 300px;
}

.result{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
	color: var(--baseFont);
    gap: 5px;

}

.calculate{
    background-image: url('/public/img/png/fon_l.jpg');
    border-radius: 20px;
    padding: 20px;
    color: var(--baseFont);
    box-shadow: inset 0 0 7px 3px #733f26;
}

.calculateName{
    background-image: url('/public/img/png/fon_l.jpg');
    border-radius: 20px;
    padding: 20px;
    color: var(--baseFont);
    box-shadow: inset 0 0 7px 3px #733f26;
    min-width: 1300px;
}

.noBook{
    display: flex; 
	justify-content: space-evenly; 
}

.nameList{
    box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 55%;
	color: var(--baseFont);
	font-size: 22px;
	align-items: center;
	overflow: auto;
	height: 700px;
    text-align: center;
}

.krugi{
    background-image: url('/public/img/png/krug.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 450px;
    height: 450px;
}

.match_box{
    display: flex;
    gap: 15px;
    justify-content: center;
}

.calcInput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 650px;
    margin-bottom: 15px;
}

::placeholder{
    font-size: 16px;
    font-style: italic;
}

.calcInput input {
    width: 200px;
}



.middle{
    text-align: center;
}

.calc,
.clean{
    width: 170px;
    margin-right: 10px;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 15px;
}

.calc{
    background: #63a949;
}

.clean{
    background: #d15c50;
}

.navLink{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 22px;
    color: white;
}

.navLink:hover{
    color: var(--baseBackground);
}