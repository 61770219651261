
input{
    outline: none;
    border-radius: 20px;
    border: 1px solid var(--border-primary);
    color: #000;
    font-size: 16px;
    padding: 10px;
    background-color: var(--inputBg);
    border: 1px dotted black;
    text-align: center;
}

input:disabled{
    background: rgb(228, 222, 189);
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"] {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    border-radius: 20px;
    border: 1px dotted black;
}

::-webkit-input-placeholder{
    color: var(--baseFont);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background-image: url('../../../../public/img/svg/cross.svg');
}

input[type="search"]{
    text-align: left;
    border: none;
    border-radius: 5px;
}