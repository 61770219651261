.calcInput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 650px;
    margin-bottom: 10px;
}

.buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 10px;
    width: 650px;
    margin-bottom: 10px;
    align-items: center;
}

::placeholder{
    font-size: 16px;
    font-style: italic;
}

.calcInput input {
    width: 200px;
}

button:hover{
    background-color: var(--box-shadow) !important;
    color: white !important;
} 
.info{
    position: absolute;
    z-index: 10;
    width: 650px;
}

.info h5{
    margin-left: 67%;
}

.info div{
    padding: 5px;
    font-size: 14px;
    background-image: url('/public/img/png/fon_l.jpg');
} 

.calculation .calcResult{
    flex-direction: row;
}

.calcResult{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items:center;
    height: auto;
}


.grafic{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
}

.grafic__box{
    display: flex;
}

.grafic img{
    width: 300px;
    height: 190px;
}

.grafic_e{
    display: flex;
}

/*gain*/

.gain__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.gain__content{
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin: 5px;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin: 5px;
    flex-grow: 1;
}

.gain{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    padding: 3px 15px;
    width: 100%;
    max-width: 300px;
}

.krugDescription{
    position: absolute;
    z-index: 20;
    left: 50%;
}

.yearDescription{
    position: absolute;
    left: 50%;
    z-index: 20;
}

.box{
    position: absolute;
    top: 110%;
    left: -7%;
    z-index: 20;
}
.gain:hover{
    background-color: #ddd1a3;
}

.gain img{
    width: 40px;
    height: 40px;
}

.gainText{
    font-size: 13px;
}

.fullgain{
    position: relative;
    cursor: pointer;
    text-align: center;
    padding:1px 10px;
    border-radius: 10px;
}


/*forecast*/

.forecast{
    display: flex;
    justify-content: space-between;
    min-width: 2300px;
}

.forecastSimple{
    display: flex;
    justify-content: space-between;
}



.forecast__container{
    width: 49.5%;
    background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.period__forecast{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.forecast__container h2{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    color: var(--baseFont);
}


.periodItem{
    width: 280px;
    height: 282px;
    background-image: url('/public/img/png/forecast_period.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 45px;
    position: relative;
}

.yearItem{
    width: 280px;
    height: 282px;
    background-image: url('/public/img/png/forecast_years.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    margin-top: 15px;

}

@media print {
    .periodItem,
    .yearItem {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

.periods{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}


.columnYears__left{
    position: absolute;
    margin-top: 15px;
    margin-left: -45px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.columnYears__right{
    position: absolute;
    margin-top: 15px;
    margin-left: 290px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    visibility: hidden;
}

.period__container:nth-child(3n){
    .columnYears__right{
        visibility: visible;
    }
} 

/* прогногз */

.event{
    display: flex;
    justify-content: space-around;
    text-align: center;
}


@media (max-width: 1350px) {
    .calcInput{
        margin-top: 50px;
    }
}


a{
    text-decoration: none;
}

a:hover{
    font-weight: 600;
    color: var(--box-shadow);
}

/*cards*/

.cards__container{
    width: 45%;
    background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    border-radius: 20px;
    padding: 25px 45px;
    text-align: center;
}

.card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 16px;
    gap: 10px;
}

.cardIMG{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card p{
    margin-bottom: 5px;
}

.card img{
    width: 40px;
    height: 40px;
}

.card__center{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.card__center img{
    width: 20px;
    height: 25px;
}

.changeBtn{
    position: relative;
    border-radius: 5px;
    left: 90px;
    top: -5px;
    background-color: var(--baseBackground);
    color: var(--baseFont)
}

.navLink{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 22px;
    color: white;
}
