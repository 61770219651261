nav{
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.header{
	position: fixed;
	z-index: 158;
}

.header,
.footer{
    box-sizing: border-box;
    width: 100%;
	padding: 15px;
    background-color: var(--baseBackground);
    box-shadow: inset 0 0 7px 3px #733f26;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
	color: var(--baseFont);
}

.footer{
    position: relative;
    bottom: 0;
	padding: 15px 0;
}

.title{
	text-align: center;
}

.book{
	margin: 0 auto;
    background-image: url('../public/img/png/book.png');

	display: flex; 
	justify-content: space-evenly; 
	width:97vw;
    height: 1100px;
    border-radius: 20px;
    background-size: contain;
	background-repeat: no-repeat;
    background-position: center top;
	overflow: auto;
	box-sizing: border-box;
}

.form__cotainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 5%;
}

section {
	font-size: 22px;
	width: 100%;
	max-width: 500px;
	min-height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1rem;
	background-color: var(--formBackground);
	border-radius: 15px;
	color: white;
	margin-bottom: 15px;
}

section a,
section a:visited,
.whiteLink {
	color: white;
}

form {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex-grow: 1;
	padding-bottom: 1rem;
}

.instructions {
	font-size: 0.75rem;
	border-radius: 0.5rem;
	background: var(--baseFont);
	color: #fff;
	padding: 0.25rem;
	position: relative;
	bottom: -10px;
}

.backBtn{
	font-weight: 600;
	background-color: var(--inputBg);
	cursor: pointer;
	border-radius: 20px;
	border: 1px solid rgba(0, 0, 0, 0.40);
    box-shadow: 0px 4px 4px 0px var(--box-shadow);
    text-align: center;
    font-size: 18px;
    padding: 10px 15px;
	transition: all .3s ease-in;
}

.backBtn a{
	color: var(--baseFont) !important;
}

.backBtn:hover{
	background-color: var(--box-shadow) !important;
}

.backBtn:hover a{
    color: white !important;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
	position: absolute;
	left: -9999px;
}

.hide {
    display: none;
}

.valid {
	color: rgb(31, 163, 31);
	margin-left: 0.25rem;
}

.invalid {
	color: rgb(199, 96, 96);
	margin-left: 0.25rem;
}

.errmsg {
	color: rgb(199, 96, 96);
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.line__container{
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.line {
	display: inline-block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid transparent;
	-webkit-text-fill-color: var(--baseFont);
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	transition: background-color 5000s ease-in-out 0s;
	outline: none;
}

.register{
	margin-top: 15px;
}

.almanac .leftPage{
	align-items: baseline !important;
}

.rightPage,
.leftPage{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 43%;
	margin-top: 50px;
	color: var(--baseFont);
	font-size: 18px;
	align-items: center; 
	overflow: auto;
	height: 970px;
}

.leftPage img{
	width: 400px;
	height: 400px;
}

.colums{
    display: flex;
    flex-direction: column;
    gap: 15px;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--scroll);
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.author{
	align-items: center !important;
}


.header__addBtn{
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items:  flex-start ;
    gap: 25px;
}

.header__addBtn input {
	width: 100%;
} 

.search{
    width: 100%;
}

.collapse{
	position: relative;
	top: -40px;
	width: 15px;
	left: 92%;
}

@media (max-width: 1450px){
	.collapse{
		left: 90%;
	}
}


@media (max-width: 1100px){
	.collapse{
		left: 87%;
	}
}

.calcResult{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items:center;
    height: auto;
}



.calculation .calcResult{
    flex-direction: row;
}

/* @media (max-width: 1260px) {
	.calculation .calcResult{
		flex-direction: column;
	}
} */

.popupTool {
    z-index: 10;
    position: absolute;
	width: 350px;
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
    padding: 15px 25px;
    text-align: justify;

    box-shadow: inset 0 0 7px 3px #733f26;
	background-image: url('/public/img/png/fon_l.jpg');
    transition: transform 0.3s ease-in-out;
}

.popLink{
    text-decoration: underline;
    font-size: 16px;
    margin-top: 15px;
    transition: all 0.3;
}

.popLink:hover{
    color: black;
}

.showPwd{
	display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
	cursor: pointer;
}

.restoreInfo{
	text-align: center;
	color: var(--inputBg);
}