@media (max-width: 1150px){
    .rightPage,
    .leftPage{
        width: 44vw;
        height: 500px !important;
    }
} 

@media (max-width: 1299px){
    .rightPage,
    .leftPage{
        height: 580px;
    }
} 


@media (min-width: 1300px){
    .rightPage,
    .leftPage{
        height: 700px;
    }
}

@media (min-width: 1400px){
    .rightPage,
    .leftPage{
        height: 760px;
    }
}

@media (min-width: 1500px){
    .book{
        width: 99vw;
    }
    .rightPage,
    .leftPage{
        height: 830px;
    }
}

@media (min-width: 1600px){
    .book{
        width: 99vw;
    }
    .rightPage,
    .leftPage{
        /* height: 870px; */
        height: 900px;
    }
}

@media (min-width: 1700px) and (max-width: 1800px){
    .book{
        width: 99vw;
    }
    .rightPage,
    .leftPage{
        height: 940px;
    }
}

@media (min-width: 1900px){
    .book{
        width: 92vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}

@media (min-width: 2000px){
    .book{
        width: 88vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}

@media (min-width: 2100px){
    .book{
        width: 83vw;
    }
    .rightPage,
    .leftPage{
        /* height: 970px; */
        height: 995px;
    }
}

@media (min-width: 2200px) {
    .book{
        width: 80vw;
        height: 1100px;
    }
    .rightPage,
    .leftPage{
        /* height: 925px; */
        height: 995px;
    }
}

@media (min-width: 2300px) {
    .book{
        width: 78vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}

@media (min-width: 2400px) {
    .book{
        width: 75vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}


@media (min-width: 2500px) {
    .book{
        width: 71vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}

@media (min-width: 2600px) {
    .book{
        width: 69vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}


@media (min-width: 2700px) {
    .book{
        width: 66vw;
    }
    .rightPage,
    .leftPage{
        height: 970px;
    }
}

@media (min-width: 2800px) {
    .book{
        width: 64vw;
    }

    .rightPage,
    .leftPage{
        height: 970px;
    }
}