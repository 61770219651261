.profileItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileItem p {
    font-weight: 600;
}

button{
    border-radius: 20px;
    padding: 5px 10px;
    background-color: var(--baseBackground);
}

.profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile input{
    width: 400px;
    background-color: var(--inputBg);
}

.profile span{
    width: 400px;
}

.logoutBtn{
    position: relative;
    left: 50px;
}

@media (max-width: 1350px) {
    .profile input{
        width: 300px;
    }
}
