.starImg{
    width: 300px;
    height: 350px;
    float: left;
    margin: 0px 15px 15px 0px;
}

.bookImg{
    width: 350px;
    float: left;
    margin: 0px 15px 15px 0px;
    height: 300px;
}

.starText{
    text-align: justify;
}

.title{
    text-align: center;
    margin-bottom: 10px;
}

.description{
    text-align: justify;
}

.container{
    width: 96%;
}