select{
    outline: none;
    width: 200px;
    height: 34px;
    border:  1px solid transparent;
    font-size: 16px;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--blueCircle);
    color: white;

}

select > option:checked { 
    color: #000 !important;
}

option{
    background-color: var(--baseBackground);
    color: black;
}

option:disabled{
    color: grey;
}

.dropdown__box{
    position: absolute;
    left: 350px;
    width: 200px;
}
