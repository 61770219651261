.krug__day{
    width: 500px;
    height: 500px;
    background-image: url('/public/img/png/dayCircle.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__month{
    width: 500px;
    height: 500px;
    background-image: url('/public/img/png/monthCircle.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__year{
    width: 500px;
    height: 500px;
    background-image: url('/public/img/png/yearCircle.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__period{
    width: 500px;
    height: 500px;
    background-image: url('/public/img/png/periodCircle.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__telo{
    width: 300px;
    height: 220px;
    background-image: url('/public/img/png/krug__telo.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__oblik{
    width: 300px;
    height: 220px;
    background-image: url('/public/img/png/krug__oblik.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

.krug__chislo{
    width: 310px;
    height: 250px;
    background-image: url('/public/img/png/krug__chislo.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
    margin-top: -15px;
}

@media print {
    .krug__period,
    .krug__year {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}
