
.popup {
    z-index: 10;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: var(--baseBackground);
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    max-width: 500px;
    width: 100%;
    transition: transform 0.3s ease-in-out;
}

.popupContent{
    display: flex;
    align-items: center;
    gap: 15px;
}

.closeButton {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 6px 12px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 10px;
}


.popup img {
    height: auto;
    max-width: 100%;
    display: block;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.modal{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.38);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transform: 0.5s;
    z-index: 10;
}

.modal.active{
    opacity: 1;
    pointer-events: all;
}

.modal__content{
    width: 600px;
    height: 400px;
    padding: 20px;
    background-color: var(--baseBackground);
    transform: scale(.5);
    transition: 0.4s all;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal__content h3{
    margin-bottom: 20px;
}

.modal__content.active{
    transform: scale(1);
}

.modal__frame{
    width: 85%;
    height: 85%;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(245, 240, 240, 0.36);
    text-align: center;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

.modal__frame p{
    font-size: 18px;
}

.modalBtn__content{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.modal__data{
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
}

.modal__data h5{
    font-size: 18px;
}