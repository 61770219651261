/*средний круг c ё */

.krug__middle .missiya {
    position: absolute;
    cursor: pointer;
    margin-left: 146px;
    margin-top: -1px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .missiya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 168px;
    margin-top: 12px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .fiasko {
    position: absolute;
    cursor: pointer;
    margin-left: 300px;
    margin-top: 1px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .fiasko_e {
    position: absolute;
    cursor: pointer;
    margin-left: 319px;
    margin-top: 13px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .argument {
    position: absolute;
    cursor: pointer;
    margin-left: 229px;
    margin-top: 72px;
    font-size: 27px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .argument_e {
    position: absolute;
    cursor: pointer;
    margin-left: 244px;
    margin-top: 79px;
    font-size: 20px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_zadacha {
    position: absolute;
    cursor: pointer;
    margin-left: 183px;
    margin-top: 110px;
    font-size: 31px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_zadacha_e{
    position: absolute;
    cursor: pointer;
    margin-left: 201px;
    margin-top: 118px;
    font-size: 23px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .vibor_palach {
    position: absolute;
    cursor: pointer;
    margin-left: 269px;
    margin-top: 109px;
    font-size: 31px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_palach_e {
    position: absolute;
    cursor: pointer;
    margin-left: 286px;
    margin-top: 117px;
    font-size: 23px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .oblik {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    margin-top: 140px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .nevihod {
    position: absolute;
    cursor: pointer;
    margin-left: 158px;
    margin-top: 155px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .nevihod_n {
    position: absolute;
    cursor: pointer;
    margin-left: 162px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .stremlenie {
    position: absolute;
    cursor: pointer;
    margin-left: 230px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .stremlenie_e {
    position: absolute;
    cursor: pointer;
    margin-left: 243px;
    margin-top: 159px;
    font-size: 22px;
    color: var(--redCircle);
    font-weight: 500;

    z-index: 3;
}
.krug__middle .nevrag {
    position: absolute;
    cursor: pointer;
    margin-left: 316px;
    margin-top: 155px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .predatel {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 188px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antioblik {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 140px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .nepodarok {
    position: absolute;
    cursor: pointer;
    margin-left: 91px;
    margin-top: 155px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .nepodarok_n {
    position: absolute;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .kozir {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 121px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .grezi {
    position: absolute;
    cursor: pointer;
    margin-left: 285px;
    margin-top: 227px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .sut {
    position: absolute;
    cursor: pointer;
    margin-left: 223px;
    margin-top: 216px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .sut_e {
    position: absolute;
    cursor: pointer;
    margin-left: 243px;
    margin-top: 228px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antigrezi {
    position: absolute;
    cursor: pointer;
    margin-left: 321px;
    margin-top: 227px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .rutina {
    position: absolute;
    cursor: pointer;
    margin-left: 407px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .komfort {
    position: absolute;
    cursor: pointer;
    margin-left: 124px;
    margin-top: 122px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .komfort_n {
    position: absolute;
    cursor: pointer;
    margin-left: 131px;
    margin-top: 264px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .oshibka {
    position: absolute;
    cursor: pointer;
    margin-left: 383px;
    margin-top: 155px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .telo_talant{
    position: absolute;
    cursor: pointer;
    margin-left: 3px;
    margin-top: 296px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .telo_talant_e {
    position: absolute;
    cursor: pointer;
    margin-left: 25px;
    margin-top: 308px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .argument_n {
    position: absolute;
    cursor: pointer;
    margin-left: 237px;
    margin-top: 290px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antitelo{
    position: absolute;
    cursor: pointer;
    margin-left: 439px;
    margin-top: 296px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antitelo_e {
    position: absolute;
    cursor: pointer;
    margin-left: 460px;
    margin-top: 304px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_poterya{
    position: absolute;
    cursor: pointer;
    margin-left: 182px;
    margin-top: 323px;
    font-size: 31px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .vibor_poterya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 199px;
    margin-top: 330px;
    font-size: 24px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_energiya {
    position: absolute;
    cursor: pointer;
    margin-left: 269px;
    margin-top: 322px;
    font-size: 31px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .vibor_energiya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 285px;
    margin-top: 329px;
    font-size: 24px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .stremlenie_n {
    position: absolute;
    cursor: pointer;
    margin-left: 237px;
    margin-top: 372px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}
.krug__middle .bremya {
    position: absolute;
    cursor: pointer;
    margin-left: 155px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .resurs {
    position: absolute;
    cursor: pointer;
    margin-left: 309px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .uchitel {
    position: absolute;
    cursor: pointer;
    margin-left: 95px;
    margin-top: 7px;
    font-size: 30px;
    color: var(--blueCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .nuzhda {
    position: absolute;
    cursor: pointer;
    margin-left: 18px;
    margin-top: 366px;
    font-size: 30px;
    color: var(--blueCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .fars {
    position: absolute;
    cursor: pointer;
    margin-left: 453px;
    margin-top: 85px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .muchitel {
    position: absolute;
    cursor: pointer;
    margin-left: 377px;
    margin-top: 443px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .smisl {
    position: absolute;
    cursor: pointer;
    margin-left: 53px;
    margin-top: 218px;
    font-size: 43px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__middle .zapas {
    position: absolute;
    cursor: pointer;
    margin-left: 124px;
    margin-top: 188px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .zapas_n {
    position: absolute;
    cursor: pointer;
    margin-left: 131px;
    margin-top: 325px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .yadro {
    position: absolute;
    cursor: pointer;
    margin-left: 151px;
    margin-top: 227px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antiyadro {
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 227px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .nevrag_n {
    position: absolute;
    cursor: pointer;
    margin-left: 311px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .predatel_n {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .kozir_n {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 264px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .oshibka_n {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
} 


/* средний круг без ё */



.krug__middle .missiya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 154px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .fiasko_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 309px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .argument_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 69px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .vibor_zadacha_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 190px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .vibor_palach_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 277px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .oblik_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 140px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .nevihod_simple {
    position: absolute;
    cursor: pointer;
    margin-left:158px;
    margin-top: 154px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .nevihod_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 163px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .stremlenie_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 151px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .nevrag_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 316px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .predatel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antioblik_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 140px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .nepodarok_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 91px;
    margin-top: 155px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .nepodarok_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 101px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .kozir_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 348px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .grezi_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 285px;
    margin-top: 225px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .sut_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 231px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .antigrezi_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 225px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .rutina_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 408px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .komfort_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .komfort_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .oshibka_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 382px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .telo_talant_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    margin-top: 293px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .argument_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 288px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antitelo_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 294px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .vibor_poterya_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .vibor_energiya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 275px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .stremlenie_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 370px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}

.krug__middle .bremya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 155px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__middle .resurs_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 310px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle .uchitel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 95px;
    margin-top: 7px;
    font-size: 30px;
    color: var(--blueCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .nuzhda_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 18px;
    margin-top: 366px;
    font-size: 30px;
    color: var(--blueCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .fars_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 454px;
    margin-top: 84px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .muchitel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 377px;
    margin-top: 443px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .smisl_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__middle .zapas_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 124px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .zapas_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 131px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .yadro_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 151px;
    margin-top: 226px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .antiyadro_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 226px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__middle .nevrag_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 311px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .predatel_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 342px;
    margin-top: 328px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .kozir_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 342px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__middle .oshibka_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}