.category__item{
    display: flex;
}

.search{
    width: 100%;
}

.add__supItem{
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.add__supItem input{
    margin-bottom: 10px;
}

.videoDropdown{
    display: flex;
    gap: 25px;
}

.CategoryItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 42vw;
}

.CategoryItem h3{
    cursor: pointer;
}

.CategoryItem span{
    cursor: pointer;
}

.CategoryItem h3{
    margin: 0;
}

ul{
    list-style-type: none;
    margin-bottom: 10px;
    width: fit-content;
}

li{
    cursor: pointer;
}

li:hover{
    font-weight: 600;
    color: var(--box-shadow);
}

hr{
    margin: -25px auto 10px;
	padding: 0;
	height: 30px;
	border: none;
	border-bottom: 1px solid #1f1209;
	box-shadow: 0 20px 20px -20px #333;
	width: 95%;
}

.add__video{
    margin: 20px;
    display: flex;
    gap: 20px;
}

.nestedBlock{
    margin: 15px 0;
}

.nested{
    list-style-type: square;
    margin-left: 55px;
}


@media (min-width: 1900px) {
    .CategoryItem{
        width: 37vw;
    }
}

@media (min-width: 2100px) {
    .CategoryItem{
        width: 35vw;
    }
}

@media (min-width: 2200px) {
    .CategoryItem{
        width: 34vw;
    }
}


@media (min-width: 2300px) {
    .CategoryItem{
        width: 31vw;
    }
}


@media (min-width: 2500px) {
    .CategoryItem{
        width: 30vw;
    }
}

@media (min-width: 2600px) {
    .CategoryItem{
        width: 29vw;
    }
}


@media (min-width: 2700px) {
    .CategoryItem{
        width: 28vw;
    }
}

@media (min-width: 2800px) {
    .CategoryItem{
        width: 27vw;
    }
}