/*большой круг с ё */
.krug{
    width: 700px;
    height: 700px;
    background-image: url('/public/img/png/krug.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}


.krug .missiya {
    position: absolute;
    cursor: pointer;
    margin-left: 203px;
    margin-top: -2px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .missiya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 21px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .fiasko {
    position: absolute;
    cursor: pointer;
    margin-left: 419px;
    margin-top: -2px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .fiasko_e {
    position: absolute;
    cursor: pointer;
    margin-left: 451px;
    margin-top: 20px;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .argument {
    position: absolute;
    cursor: pointer;
    margin-left: 321px;
    margin-top: 100px;
    font-size: 39px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .argument_e {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 110px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_zadacha {
    position: absolute;
    cursor: pointer;
    margin-left: 256px;
    margin-top: 158px;
    font-size: 42px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_zadacha_e{
    position: absolute;
    cursor: pointer;
    margin-left: 279px;
    margin-top: 167px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .vibor_palach {
    position: absolute;
    cursor: pointer;
    margin-left: 378px;
    margin-top: 158px;
    font-size: 42px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_palach_e {
    position: absolute;
    cursor: pointer;
    margin-left: 402px;
    margin-top: 167px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .oblik {
    position: absolute;
    cursor: pointer;
    margin-left: 17px;
    margin-top: 197px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nevihod {
    position: absolute;
    cursor: pointer;
    margin-left: 224px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nevihod_n {
    position: absolute;
    cursor: pointer;
    margin-left: 229px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .stremlenie {
    position: absolute;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 217px;
    font-size: 39px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .stremlenie_e {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;

    z-index: 3;
}
.krug .nevrag {
    position: absolute;
    cursor: pointer;
    margin-left: 444px;
    margin-top: 224px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .predatel {
    position: absolute;
    cursor: pointer;
    margin-left: 491px;
    margin-top: 270px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .antioblik {
    position: absolute;
    cursor: pointer;
    margin-left: 629px;
    margin-top: 197px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nepodarok {
    position: absolute;
    cursor: pointer;
    margin-left: 129px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nepodarok_n {
    position: absolute;
    cursor: pointer;
    margin-left: 142px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .kozir {
    position: absolute;
    cursor: pointer;
    margin-left: 491px;
    margin-top: 176px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .grezi {
    position: absolute;
    cursor: pointer;
    margin-left: 402px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .sut {
    position: absolute;
    cursor: pointer;
    margin-left: 312px;
    margin-top: 303px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .sut_e {
    position: absolute;
    cursor: pointer;
    margin-left: 343px;
    margin-top: 325px;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .antigrezi {
    position: absolute;
    cursor: pointer;
    margin-left: 452px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .rutina {
    position: absolute;
    cursor: pointer;
    margin-left: 573px;
    margin-top: 304px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .komfort {
    position: absolute;
    cursor: pointer;
    margin-left: 176px;
    margin-top: 176px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .komfort_n {
    position: absolute;
    cursor: pointer;
    margin-left: 184px;
    margin-top: 376px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .oshibka {
    position: absolute;
    cursor: pointer;
    margin-left: 537px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .telo_talant{
    position: absolute;
    cursor: pointer;
    margin-left: 6px;
    margin-top: 412px;
    font-size: 57px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .telo_talant_e {
    position: absolute;
    cursor: pointer;
    margin-left: 35px;
    margin-top: 433px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .argument_n {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 405px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .antitelo{
    position: absolute;
    cursor: pointer;
    margin-left: 617px;
    margin-top: 412px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .antitelo_e {
    position: absolute;
    cursor: pointer;
    margin-left: 648px;
    margin-top: 434px;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_poterya{
    position: absolute;
    cursor: pointer;
    margin-left: 255px;
    margin-top: 455px;
    font-size: 42px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug .vibor_poterya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 277px;
    margin-top: 465px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_energiya {
    position: absolute;
    cursor: pointer;
    margin-left: 378px;
    margin-top: 455px;
    font-size: 42px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .vibor_energiya_e {
    position: absolute;
    cursor: pointer;
    margin-left: 400px;
    margin-top: 465px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug .stremlenie_n {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 520px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 600;

    z-index: 3;
}
.krug .bremya {
    position: absolute;
    cursor: pointer;
    margin-left: 217px;
    margin-top: 609px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .resurs {
    position: absolute;
    cursor: pointer;
    margin-left: 431px;
    margin-top: 609px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .uchitel {
    position: absolute;
    cursor: pointer;
    margin-left: 133px;
    margin-top: 12px;
    font-size: 40px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .nuzhda {
    position: absolute;
    cursor: pointer;
    margin-left: 25px;
    margin-top: 514px;
    font-size: 40px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .fars {
    position: absolute;
    cursor: pointer;
    margin-left: 635px;
    margin-top: 120px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .muchitel {
    position: absolute;
    cursor: pointer;
    margin-left: 528px;
    margin-top: 623px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .smisl {
    position: absolute;
    cursor: pointer;
    margin-left: 75px;
    margin-top: 304px;
    font-size: 60px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}


.krug .zapas {
    position: absolute;
    cursor: pointer;
    margin-left: 175px;
    margin-top: 270px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .zapas_n {
    position: absolute;
    cursor: pointer;
    margin-left: 185px;
    margin-top: 463px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .yadro {
    position: absolute;
    cursor: pointer;
    margin-left: 214px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .antiyadro {
    position: absolute;
    cursor: pointer;
    margin-left: 264px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .nevrag_n {
    position: absolute;
    cursor: pointer;
    margin-left: 437px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .predatel_n {
    position: absolute;
    cursor: pointer;
    margin-left: 480px;
    margin-top: 463px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .kozir_n {
    position: absolute;
    cursor: pointer;
    margin-left: 481px;
    margin-top: 376px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .oshibka_n {
    position: absolute;
    cursor: pointer;
    margin-left: 524px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__middle{
    width: 500px;
    height: 500px;
    background-image: url('/public/img/png/krug.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


@media print {
    #print,
    .krug__middle,
    .krug {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}