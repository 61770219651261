.info__header{
    width: 100%;
    background-image: url('../../../../public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    background-color: #9d673b;
    color: var(--baseFont);
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    border-radius: 20px;
    padding: 10px 0;
    margin: 0 auto;
    margin-top: 15px;
}


