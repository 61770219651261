
/*цвета кругов */

.missiya_color {
    color: var(--greenCircle);
}

.fiasko_color {
    color: var(--redCircle);
}

.argument_color {
    color: var(--greenCircle);
}

.vibor_zadacha_color {
    color: var(--greenCircle);
}

.vibor_palach_color {
    color: var(--redCircle);
}

.oblik_color {
    color: var(--greenCircle);
}
.nevihod_color {
    color: var(--redCircle);
}
.nevihod_n_color {
    color: var(--greenCircle);
}
.stremlenie_color {
    color: var(--redCircle);
}

.nevrag_color {
    color: var(--greenCircle);
}
.predatel_color {
    color: var(--redCircle);
}
.antioblik_color {
    color: var(--redCircle);
}
.nepodarok_color {
    color: var(--greenCircle);
}
.nepodarok_n_color {
    color: var(--redCircle);
}
.kozir_color {
    color: var(--greenCircle);
}
.grezi_color {
    color: var(--greenCircle);
}
.sut_color {
    color: var(--redCircle);
}

.antigrezi_color {
    color: var(--redCircle);
}
.rutina_color {
    color: var(--redCircle);
}
.komfort_color {
    color: var(--redCircle);
}
.komfort_n_color {
    color: var(--greenCircle);
}

.oshibka_color {
    color: var(--redCircle);
}
.telo_talant_color{
    color: var(--greenCircle);
}

.argument_n_color {
    color: var(--redCircle);
}
.antitelo_color{
    color: var(--redCircle);
}

.vibor_poterya_color{
    color: var(--redCircle);
}

.vibor_energiya_color {
    color: var(--greenCircle);
}

.stremlenie_n_color {
    color: var(--greenCircle);
}
.bremya_color {
    color: var(--redCircle);
}
.resurs_color {
    color: var(--greenCircle);
}

.uchitel_color {
    color: var(--blueCircle);
}

.nuzhda_color {
    color: var(--blueCircle);
}

.fars_color {
    color: var(--redCircle);
}

.muchitel_color {
    color: var(--redCircle);
}

.smisl_color {
    color: var(--blueCircle);
}


.zapas_color {
    color: var(--greenCircle);
}
.zapas_n_color {
    color: var(--redCircle);
}

.yadro_color {
    color: var(--greenCircle);
}
.antiyadro_color {
    color: var(--redCircle);
}

.nevrag_n_color {
    color: var(--redCircle);
}
.predatel_n_color {
    color: var(--greenCircle);

}
.kozir_n_color {
    color: var(--redCircle);
}
.oshibka_n_color {
    color: var(--greenCircle);
}



.period_color,
.god_color {
    color: var(--greenCircle);
}

.antiperiod_color,
.antigod_color {
    color: var(--redCircle);
}

.argument_p_color {
    color: var(--greenCircle);
}

.zadacha_p_color {
    color: var(--greenCircle);
}

.palach_p_color {
    color: var(--redCircle);
}

.ispitanie_p_color {
    color: var(--greenCircle);
}

.ispitanie_radost_p_color{
    color: var(--greenCircle);
}

.nevihod_p_color {
    color: var(--redCircle);
}
.nevihod_n_p_color {
    color: var(--greenCircle);
}
.stremlenie_p_color {
    color: var(--redCircle);
}

.nevrag_p_color {
    color: var(--greenCircle);
}
.predatel_p_color {
    color: var(--redCircle);
}

.neispitanie_p_color {
    color: var(--redCircle);
}
.nepodarok_p_color {
    color: var(--greenCircle);
}
.nepodarok_n_p_color {
    color: var(--redCircle);
}
.kozir_p_color {
    color: var(--greenCircle);
}
.grezi_p_color {
    color: var(--greenCircle);
}
.vozmojnost_p_color {
    color: var(--greenCircle);
}

.antigrezi_p_color {
    color: var(--redCircle);
}

.nevozmojnost_p_color {
    color: var(--redCircle);
}
.komfort_p_color {
    color: var(--redCircle);
}
.komfort_n_p_color {
    color: var(--greenCircle);
}

.oshibka_p_color {
    color: var(--redCircle);
}


.argument_n_p_color {
    color: var(--redCircle);
}
.antinavik_p_color{
    color: var(--redCircle);
}

.navik_slabost_p_color{
    color: var(--redCircle);
}

.vibor_poterya_p_color{
    color: var(--redCircle);
}

.vibor_energiya_p_color {
    color: var(--greenCircle);
}

.stremlenie_n_p_color {
    color: var(--greenCircle);
}

.proshloe_nauka_p_color {
    color: var(--redCircle);
}
.proshloe_opit_p_color {
    color: var(--greenCircle);
}

.navik_p_color {
    color: var(--greenCircle);
}

.navik_sila_p_color{
    color: var(--greenCircle);
}

.neispitanie_slezi_p_color {
    color: var(--redCircle);
}


.navajdenie_p_color {
    color: var(--redCircle);
}


.zapas_p_color {
    color: var(--greenCircle);
}
.zapas_n_p_color {
    color: var(--redCircle);
}

.yadro_p_color {
    color: var(--greenCircle);
}
.antiyadro_p_color {
    color: var(--redCircle);
}

.nevrag_n_p_color {
    color: var(--redCircle);
}
.predatel_n_p_color {
    color: var(--greenCircle);
}
.kozir_n_p_color {
    color: var(--redCircle);
}
.oshibka_n_p_color {
    color: var(--greenCircle);
}


/* средний круг для года */

.argument_y_color {
    color: var(--greenCircle);
}

.zadacha_y_color {
    color: var(--greenCircle);
}

.palach_y_color {
    color: var(--redCircle);
}

.ispitanie_y_color {
    color: var(--greenCircle);
}

.ispitanie_radost_y_color{
    color: var(--greenCircle);
}

.nevihod_y_color {
    color: var(--redCircle);
}
.nevihod_n_y_color {
    color: var(--greenCircle);
}
.stremlenie_y_color {
    color: var(--redCircle);
}

.nevrag_y_color {
    color: var(--greenCircle);
}
.predatel_y_color {
    color: var(--redCircle);
}

.neispitanie_y_color {
    color: var(--redCircle);
}
.nepodarok_y_color {
    color: var(--greenCircle);
}
.nepodarok_n_y_color {
    color: var(--redCircle);
}
.kozir_y_color {
    color: var(--greenCircle);
}
.grezi_y_color {
    color: var(--greenCircle);
}
.vozmojnost_y_color {
    color: var(--greenCircle);
}

.antigrezi_y_color {
    color: var(--redCircle);
}

.nevozmojnost_y_color {
    color: var(--redCircle);
}
.komfort_y_color {
    color: var(--redCircle);
}
.komfort_n_y_color {
    color: var(--greenCircle);
}

.oshibka_y_color {
    color: var(--redCircle);
}


.argument_n_y_color {
    color: var(--redCircle);

}
.antinavik_y_color{
    color: var(--redCircle);
}

.navik_slabost_y_color{
    color: var(--redCircle);
}

.vibor_poterya_y_color{
    color: var(--redCircle);
}

.vibor_energiya_y_color {
    color: var(--greenCircle);
}

.stremlenie_n_y_color {
    color: var(--greenCircle);
}

.proshloe_nauka_y_color {
    color: var(--redCircle);
}
.proshloe_opit_y_color {
    color: var(--greenCircle);
}

.navik_y_color {
    color: var(--greenCircle);
}

.navik_sila_y_color{
    color: var(--greenCircle);
}

.neispitanie_slezi_y_color {
    color: var(--redCircle);
}


.navajdenie_y_color {
    color: var(--redCircle);
}


.zapas_y_color {
    color: var(--greenCircle);
}
.zapas_n_y_color {
    color: var(--redCircle);
}

.yadro_y_color {
    color: var(--greenCircle);
}
.antiyadro_y_color {
    color: var(--redCircle);
}

.nevrag_n_y_color {
    color: var(--redCircle);
}
.predatel_n_y_color {
    color: var(--greenCircle);
}
.kozir_n_y_color {
    color: var(--redCircle);
}
.oshibka_n_y_color {
    color: var(--greenCircle);
}
