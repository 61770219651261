@import './app.css';
@import './adaptive.css';

@import './krugStyle/krug_e.css';
@import './krugStyle/krug.css';
@import './krugStyle/miniKrug.css';
@import './krugStyle/middle_krug.css';
@import './krugStyle/krugColor.css';
@import './krugStyle/forecastKrug.css';
@import './krugStyle/krugPeriod.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root{
	--baseFont: #3C1C0F;
	--placeholderFont: #0000009E;

	--box-shadow: #733F26;

	--inputBg: #F9F5E5;
	--linghtBG: #efdfaf40;
	--baseBackground: #F2E6BE;
	--formBackground: #2c1912;

	--scroll:#be9360;
	--link: #553815;

	--greenCircle: #339622;
	--redCircle: #bc090c;
	--blueCircle: #32959b;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	color: var(--baseFont);
	min-height: 100vh;
	max-height: 100vh;
	font-family: "Open Sans", sans-serif;
}

body {
	height: 100vh;
	background-image: url('../public/img/png/fon_b.jpg');
}

.App {
	height: 100vh; 
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content{
    margin: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: var(--linghtBG);
}

.contentSimple{
    margin: 15px 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

input[type='text'],
input[type='password'],
input[type='number'],
button,
textarea {
	font-family: 'Nunito', sans-serif;
	font-size: 18px;
	padding: 5px 10px;
	border-radius:20px;
	border: 1px solid transparent;
	transition: all 0.3s;
	border: 1px dotted black;
	text-align: center;
}

.closed{
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-top: 25px;
}

.noAccess{
	text-align: center;
	height: 70vh;
}

.noAccess p{
	font-size: 25px;
	color: white;
	margin-bottom: 10px;
} 

.redirect{
	background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
	color: var(--baseFont);
	border-radius: 20px;
	margin: 0 30px;
	text-align: center;
	font-size: 25px;
	padding: 10px;
}

.none{
	display: none;
}

a{
	color: inherit;
}

.page{
	display: flex;
	flex-direction: column;
	gap: 25px;
}


.custom-popup-class {
	background-color: var(--baseBackground);
	width: 400px; 
	color: var(--baseFont);
}

@media print {
	.header,
	nav,
	.footer{
		display: none;
	}
}

.printBtn{
	position: absolute;
    right: 50px;
	top: 70px;
	z-index: 50;
}

@keyframes spinner {
	0% {
	opacity: 1
	}

	100% {
		opacity: 0
	}
}

.spinner div {
	left: 94px;
	top: 48px;
	position: absolute;
	animation: spinner linear 1s infinite;
	background: var(--box-shadow);
	width: 12px;
	height: 24px;
	border-radius: 6px / 12px;
	transform-origin: 6px 52px;
}

.spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -0.9166666666666666s;
	background: var(--box-shadow);
}

.spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -0.8333333333333334s;
	background: var(--box-shadow);
}

.spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.75s;
	background: var(--box-shadow);
}

.spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.6666666666666666s;
	background: var(--box-shadow);
}

.spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.5833333333333334s;
	background: var(--box-shadow);
}

.spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.5s;
	background: var(--box-shadow);
}

.spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.4166666666666667s;
	background: var(--box-shadow);
}

.spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.3333333333333333s;
	background: var(--box-shadow);
}

.spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.25s;
	background: var(--box-shadow);
}

.spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.16666666666666666s;
	background: var(--box-shadow);
}

.spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.08333333333333333s;
	background: var(--box-shadow);
}

.spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
	background: var(--box-shadow);
}

.spinner {
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	position: absolute;
    top: 50%;
    left: 45%;
}

.spinner div {
	box-sizing: content-box;
}

.notFound{
	margin: 0 auto;
	margin-top: 10%;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.notFound h2{
	font-size: larger;
	color: white;
}

.highlight,
mark {
	background-color: transparent;
}
