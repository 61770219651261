.extermalMatch p{
    font-size: 14px;
}

.extermalMatch{
    margin-top: 25px;
    width: 350px;
}

.extermalMatch h2,
.extermalMatch h3{
    margin: 10px 0;
}
