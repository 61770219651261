.tooltip{
    width: 350px;
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
    background-image: url('/public/img/png/fon_l.jpg');
    padding: 15px 25px;
    text-align: justify;
    color: var(--baseFont);
    font-size: 16px;
    border-radius: 5px;
    box-shadow: inset 0 0 7px 3px #733f26;
    z-index: 10;
}

.link{
    text-decoration: underline;
    font-size: 16px;
    margin-top: 15px;
    transition: all 0.3;
}

.link:hover{
    color: black;
}
