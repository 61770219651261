/*большой круг без ё */

.krug .missiya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 215px;
    margin-top: -2px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .fiasko_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 431px;
    margin-top: -2px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .argument_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 102px;
    font-size: 39px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .vibor_zadacha_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 268px;
    margin-top: 158px;
    font-size: 42px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .vibor_palach_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 389px;
    margin-top: 158px;
    font-size: 42px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .oblik_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 17px;
    margin-top: 197px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nevihod_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 224px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nevihod_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 229px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .stremlenie_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 217px;
    font-size: 39px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .nevrag_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 445px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .predatel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 491px;
    margin-top: 270px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
    overflow: hidden;
}
.krug .antioblik_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 629px;
    margin-top: 197px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nepodarok_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 128px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .nepodarok_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 143px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .kozir_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 491px;
    margin-top: 176px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .grezi_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 402px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .sut_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 324px;
    margin-top: 303px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .antigrezi_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 452px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .rutina_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 573px;
    margin-top: 304px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .komfort_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 176px;
    margin-top: 176px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .komfort_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 185px;
    margin-top: 376px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .oshibka_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 537px;
    margin-top: 223px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .telo_talant_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 18px;
    margin-top: 411px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .argument_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 405px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .antitelo_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 628px;
    margin-top: 412px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .vibor_poterya_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 266px;
    margin-top: 454px;
    font-size: 42px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .vibor_energiya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 390px;
    margin-top: 454px;
    font-size: 42px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .stremlenie_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 330px;
    margin-top: 520px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 600;

    z-index: 3;
}
.krug .bremya_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 218px;
    margin-top: 609px;
    font-size: 60px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .resurs_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 432px;
    margin-top: 609px;
    font-size: 60px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .uchitel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 133px;
    margin-top: 12px;
    font-size: 40px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .nuzhda_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 25px;
    margin-top: 514px;
    font-size: 40px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .fars_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 635px;
    margin-top: 120px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .muchitel_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 528px;
    margin-top: 623px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .smisl_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 75px;
    margin-top: 304px;
    font-size: 60px;
    color: var(--blueCircle);
    font-weight: 600;
    z-index: 3;
}


.krug .zapas_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 176px;
    margin-top: 270px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .zapas_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 186px;
    margin-top: 463px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .yadro_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 215px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .antiyadro_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 265px;
    margin-top: 324px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug .nevrag_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 437px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .predatel_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 480px;
    margin-top: 463px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .kozir_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 481px;
    margin-top: 376px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug .oshibka_n_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 524px;
    margin-top: 419px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.none_simple{
    display: none;
    z-index: 3;
}