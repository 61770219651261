.period{
    position: absolute;
    cursor: pointer;
    margin-left: 67px;
    margin-top: 5px;
    font-size: 38px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.antiperiod{
    position: absolute;
    cursor: pointer;
    margin-left: 183px;
    margin-top: 5px;
    font-size: 38px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.vozmojnost_p{
    position: absolute;
    cursor: pointer;
    margin-left: 27px;
    margin-top: 72px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.nevozmojnost_p{
    font-size: 36px;
    color: var(--redCircle);
    position: absolute;
    cursor: pointer;
    margin-left: 225px;
    margin-top: 72px;
    font-weight: 500;
    z-index: 3;
}

.argument_p{
    position: absolute;
    cursor: pointer;
    margin-left: 126px;
    margin-top: 65px;
    font-size: 33px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.zadacha_p{
    position: absolute;
    cursor: pointer;
    margin-left: 78px;
    margin-top: 123px;
    font-size: 33px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.palach_p{
    position: absolute;
    cursor: pointer;
    margin-left: 175px;
    margin-top: 123px;
    font-size: 33px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.stremlenie_p{
    position: absolute;
    cursor: pointer;
    margin-left: 126px;
    margin-top: 170px;
    font-size: 33px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.ispitanie_p{
    position: absolute;
    cursor: pointer;
    margin-left: 73px;
    margin-top: 222px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.neispitanie_p{
    position: absolute;
    cursor: pointer;
    margin-left: 178px;
    margin-top: 222px;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.navik_p{
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    position: absolute;
    cursor: pointer;
    margin-left: 26px;
    margin-top: 170px;
    z-index: 3;
}
.antinavik_p{
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    position: absolute;
    cursor: pointer;
    margin-left: 224px;
    margin-top: 170px;
    z-index: 3;
}

.duration{
    position: absolute;
    margin-top: 300px;
    margin-left: 95px;
    font-weight: 600;
    z-index: 3;
}

.year__circle_year{
    font-weight: 600;
    text-align: center;
    position: absolute;
    margin-top: -15px;
    margin-left: 115px;
    z-index: 3;
}


/*года*/


.year__god{
    position: absolute;
    cursor: pointer;
    margin-left: 66px;
    margin-top: 3px;
    font-size: 38px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.year__antigod{
    position: absolute;
    cursor: pointer;
    margin-left: 182px;
    margin-top: 5px;
    font-size: 38px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.year__vozmojnost_y{
    position: absolute;
    cursor: pointer;
    margin-left: 27px;
    margin-top: 72px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.year__nevozmojnost_y{
    font-size: 36px;
    color: var(--redCircle);
    position: absolute;
    cursor: pointer;
    margin-left: 224px;
    margin-top: 72px;
    font-weight: 500;
    z-index: 3;
}

.year__argument_y{
    position: absolute;
    cursor: pointer;
    margin-left: 125px;
    margin-top: 65px;
    font-size: 33px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.year__zadacha_y{
    position: absolute;
    cursor: pointer;
    margin-left: 78px;
    margin-top: 122px;
    font-size: 33px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.year__palach_y{
    position: absolute;
    cursor: pointer;
    margin-left: 174px;
    margin-top: 122px;
    font-size: 33px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.year__stremlenie_y{
    position: absolute;
    cursor: pointer;
    margin-left: 126px;
    margin-top: 170px;
    font-size: 33px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.year__ispitanie_y{
    position: absolute;
    cursor: pointer;
    margin-left: 72px;
    margin-top: 222px;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.year__neispitanie_y{
    position: absolute;
    cursor: pointer;
    margin-left: 178px;
    margin-top: 222px;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.year__navik_y{
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    position: absolute;
    cursor: pointer;
    margin-left: 26px;
    margin-top: 170px;
    z-index: 3;
}
.year__antinavik_y{
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    position: absolute;
    cursor: pointer;
    margin-left: 223px;
    margin-top: 169px;
    z-index: 3;
}


.year__column_years{
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.year__cicle_year{
    position: absolute;
    margin-left: 115px;
    font-weight: 600;
    margin-top: -20px;
    z-index: 3;
}

/*мини круги в калькуляторе */
/*структура числа*/

.sudba{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 98px;
    margin-left: 144px;
}

.rost{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 98px;
    margin-left: 47px;
}

.degradaciya{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 98px;
    margin-left: 242px;
}

.vozmojnost{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 18px;
    margin-left: 144px;
}

.navajdenie{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 18px;
    margin-left: 47px;
}

.iskushenie{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 18px;
    margin-left: 241px;
}

.otdih{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 181px;
    margin-left: 145px;
}

.obuza{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 181px;
    margin-left: 48px;
}

.mucheniya{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 181px;
    margin-left: 241px;
}


/*карта облика*/

.oblik_proval__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 85px;
    margin-left: 228px;
}

.oblik_uspeh__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 85px;
    margin-left: 52px;
} 

.vozmojnost__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 21px;
    margin-left: 265px;
} 

.navajdenie__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 21px;
    margin-left: 15px;
} 

.vigoda__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 140px;
    margin-left: 265px;
} 

.ubitok__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 140px;
    margin-left: 15px;

} 

.trati__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 29px;
    margin-left: 182px;
} 

.economiya__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 6px;
    margin-left: 143px;
} 

.dohod__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 29px;
    margin-left: 97px;
}

.shik__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 65px;
    margin-left: 142px;
}

.shablon__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 134px;
    margin-left: 182px;
} 

.krasota__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 111px;
    margin-left: 142px;
} 

.stil__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 134px;
    margin-left: 98px;
} 

.prostota__oblik{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 170px;
    margin-left: 142px;
}

/*карта тела*/

.telo_krah__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 85px;
    margin-left: 228px;
}

.telo_talant__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 85px;
    margin-left: 53px;
} 

.vozmojnost__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 22px;
    margin-left: 264px;
} 

.navajdenie__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 22px;
    margin-left: 16px;
} 

.vigoda__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 140px;
    margin-left: 264px;
} 

.ubitok__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 140px;
    margin-left: 15px;

} 

.trati__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 29px;
    margin-left: 184px;
} 

.economiya__telo{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 6px;
    margin-left: 143px;
} 

.dohod__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 31px;
    margin-left: 97px;
}

.shik__telo{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 65px;
    margin-left: 143px;
}

.starost__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 133px;
    margin-left: 183px;
} 

.zdorovye__telo{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 111px;
    margin-left: 143px;
} 

.molodost__telo{
    position: absolute;
    cursor: pointer;
    font-size: 36px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 133px;
    margin-left: 96px;
} 

.len__telo{
    position: absolute;
    cursor: pointer;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    margin-top: 170px;
    margin-left: 143px;
}