.calculate{
    background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    border-radius: 20px;
    padding: 10px 10px 20px 10px;
    min-width: 1700px;
}
.krug{
    width: 700px;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    background-image: url('../img/krug.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.calcBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
} 
.calcCard{
    text-align: center;
    margin-right: 50px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
} 


.calc__header{
    display: flex;
    justify-content: center;
}

.calc__header form{
    padding-bottom: 0;
}

.calcInput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 650px;
    margin-bottom: 10px;
}

.buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 10px;
    width: 650px;
    margin-bottom: 10px;
    align-items: center;
}

::placeholder{
    font-size: 16px;
    font-style: italic;
}

.calcInput input {
    width: 200px;
}

button:hover{
    background-color: var(--box-shadow) !important;
    color: white !important;
} 

.forecast{
    display: flex;
    justify-content: space-between;
    min-width: 2300px;
    margin: 0 auto;
}

.forecastSimple{
    display: flex;
    justify-content: space-between;
}

