.container{
    background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    border-radius: 20px;
    padding: 20px;
    min-width: 1550px;
}

.header{
    display: flex;
    gap: 35px;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.header form{
    flex-grow: 0;
    padding-bottom: 0rem;
}

.dateInput{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.calcInput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 650px;
    margin-bottom: 15px;
}

.buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 650px;
    margin-bottom: 15px; 
    margin: 0 auto;
}

::placeholder{
    font-size: 16px;
    font-style: italic;
}

.calcInput input {
    width: 200px;
}

button:hover{
    background-color: var(--box-shadow) !important;
    color: white !important;
} 

.cards{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardsBlock{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
}
