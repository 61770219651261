
.krug__period .period_simple,
.krug__day .period_simple,
.krug__year .god_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 155px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__period .antiperiod_simple,
.krug__day .antiperiod_simple,
.krug__year .antigod_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 308px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__period .argument_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 69px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .zadacha_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .palach_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .ispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    margin-top: 147px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .ispitanie_radost_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 80px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .nevihod_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left:157px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .nevihod_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 162px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .stremlenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 151px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .nevrag_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 315px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .predatel_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 187px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .neispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 146px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .nepodarok_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 89px;
    margin-top: 153px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .nepodarok_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .kozir_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .grezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 284px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .vozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 231px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__period .antigrezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 321px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .nevozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 408px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle); 
    font-weight: 600;
    z-index: 3;
}
.krug__period .komfort_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .komfort_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .oshibka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 382px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__period .argument_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 288px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .antinavik_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 294px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .navik_slabost_p_simple{
    margin-left: 449px;
    margin-top: 371px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    position: absolute;
}

.krug__period .vibor_poterya_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .vibor_energiya_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 275px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .stremlenie_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 370px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}

.krug__period .proshloe_nauka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 154px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__period .proshloe_opit_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 307px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__period .navik_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 294px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__period .navik_sila_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 370px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__period .neispitanie_slezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 450px;
    margin-top: 81px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__period .navajdenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__period .zapas_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .zapas_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .yadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 151px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .antiyadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 187px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__period .nevrag_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 310px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .predatel_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .kozir_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__period .oshibka_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}


/* средний круг для года */

.krug__year .argument_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 234px;
    margin-top: 69px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .zadacha_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .palach_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 105px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .ispitanie_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    margin-top: 147px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .ispitanie_radost_y_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 12px;
    margin-top: 80px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .nevihod_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left:157px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .nevihod_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 162px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .stremlenie_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 151px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .nevrag_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 315px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .predatel_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 348px;
    margin-top: 187px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .neispitanie_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 146px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .nepodarok_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 89px;
    margin-top: 153px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .nepodarok_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .kozir_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 348px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .grezi_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 284px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .vozmojnost_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 231px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__year .antigrezi_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .nevozmojnost_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 408px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__year .komfort_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .komfort_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .oshibka_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 382px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__year .argument_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 234px;
    margin-top: 288px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .antinavik_y_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 293px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .navik_slabost_y_simple{
    margin-left: 450px;
    margin-top: 371px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    position: absolute;
}

.krug__year .vibor_poterya_y_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .vibor_energiya_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .stremlenie_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 370px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}

.krug__year .proshloe_nauka_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 154px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__year .proshloe_opit_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 308px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__year .navik_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    z-index: 3;
    margin-top: 293px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__year .navik_sila_y_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    z-index: 3;
    margin-top: 369px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__year .neispitanie_slezi_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 81px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__year .navajdenie_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__year .zapas_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .zapas_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .yadro_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 150px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .antiyadro_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__year .nevrag_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 311px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .predatel_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 342px;
    margin-top: 328px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .kozir_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__year .oshibka_n_y_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}


/*круг месяца*/

.krug__month .period_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 154px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__month .antiperiod_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 307px;
    margin-top: -1px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__month .argument_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 69px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .zadacha_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 106px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .palach_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 106px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .ispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 147px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .ispitanie_radost_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 80px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .nevihod_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left:157px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .nevihod_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 162px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .stremlenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 151px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .nevrag_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 315px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .predatel_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 187px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .neispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 448px;
    margin-top: 146px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .nepodarok_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 89px;
    margin-top: 153px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .nepodarok_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 295px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .kozir_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 348px;
    margin-top: 121px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .grezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 284px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .vozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 231px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__month .antigrezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 321px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .nevozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 408px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__month .komfort_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 122px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .komfort_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 131px;
    margin-top: 266px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .oshibka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 382px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__month .argument_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 234px;
    margin-top: 288px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .antinavik_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 294px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .navik_slabost_p_simple{
    margin-left: 450px;
    margin-top: 371px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    position: absolute;
}

.krug__month .vibor_poterya_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .vibor_energiya_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .stremlenie_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 370px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}

.krug__month .proshloe_nauka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 154px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__month .proshloe_opit_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 307px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__month .navik_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 294px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__month .navik_sila_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 370px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__month .neispitanie_slezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 448px;
    margin-top: 81px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__month .navajdenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__month .zapas_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .zapas_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .yadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 150px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .antiyadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 188px;
    margin-top: 227px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__month .nevrag_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 310px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .predatel_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 328px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .kozir_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__month .oshibka_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}



/*средний круг для дня*/

.krug__day .argument_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 69px;
    font-size: 32px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .zadacha_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 106px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .palach_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 106px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .ispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 146px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .ispitanie_radost_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 14px;
    margin-top: 79px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .nevihod_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left:156px;
    margin-top: 154px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .nevihod_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 163px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .stremlenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 151px;
    font-size: 32px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .nevrag_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 316px;
    margin-top: 156px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .predatel_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 349px;
    margin-top: 187px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .neispitanie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 449px;
    margin-top: 146px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .nepodarok_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 89px;
    margin-top: 153px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .nepodarok_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .kozir_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 348px;
    margin-top: 120px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .grezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 285px;
    margin-top: 226px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .vozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 231px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__day .antigrezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 321px;
    margin-top: 226px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .nevozmojnost_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 409px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__day .komfort_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 120px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .komfort_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .oshibka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 382px;
    margin-top: 155px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__day .argument_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 235px;
    margin-top: 288px;
    font-size: 30px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .antinavik_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 450px;
    margin-top: 294px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .navik_slabost_p_simple{
    margin-left: 450px;
    margin-top: 370px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
    position: absolute;
}

.krug__day .vibor_poterya_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 189px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .vibor_energiya_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 276px;
    margin-top: 317px;
    font-size: 40px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .stremlenie_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 236px;
    margin-top: 370px;
    font-size: 30px;
    color: var(--greenCircle);
    font-weight: 500;

    z-index: 3;
}

.krug__day .proshloe_nauka_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 153px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}
.krug__day .proshloe_opit_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 308px;
    margin-top: 435px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 600;
    z-index: 3;
}

.krug__day .navik_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 294px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__day .navik_sila_p_simple{
    position: absolute;
    cursor: pointer;
    margin-left: 13px;
    z-index: 3;
    margin-top: 370px;
    font-size: 43px;
    color: var(--greenCircle);
    font-weight: 500;
}

.krug__day .neispitanie_slezi_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 448px;
    margin-top: 80px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}


.krug__day .navajdenie_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 217px;
    font-size: 43px;
    color: var(--redCircle);
    font-weight: 600;
    z-index: 3;
}


.krug__day .zapas_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 123px;
    margin-top: 188px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .zapas_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 327px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .yadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 151px;
    margin-top: 225px;
    font-size: 28px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .antiyadro_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 187px;
    margin-top: 225px;
    font-size: 28px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}

.krug__day .nevrag_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 310px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .predatel_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 328px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .kozir_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 341px;
    margin-top: 265px;
    font-size: 26px;
    color: var(--redCircle);
    font-weight: 500;
    z-index: 3;
}
.krug__day .oshibka_n_p_simple {
    position: absolute;
    cursor: pointer;
    margin-left: 373px;
    margin-top: 296px;
    font-size: 26px;
    color: var(--greenCircle);
    font-weight: 500;
    z-index: 3;
}
