.link{
    color: inherit;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
}

.link:hover{
    -webkit-text-shadow: .5px .5px .5px #3C1C0F;
    -moz-text-shadow: .5px .5px .5px #3C1C0F;
        text-shadow: .5px .5px .5px #3C1C0F;
}

.noAccess .link{
    color: white;
}

.noAccess .link:hover{
    color: var(--baseBackground);
}