.plotpage{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url('/public/img/png/fon_l.jpg');
    box-shadow: inset 0 0 7px 3px #733f26;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    min-width: 1750px;
}

.plotpage form {
    flex-grow: 0;
    padding-bottom: 0rem;
}

.calcInput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 15px 0;
}

::placeholder{
    font-size: 16px;
    font-style: italic;
}

.calcInput input {
    width: 160px;
}

.krug{
    width: 700px;
    height: 700px;
    background-image: url('/public/img/png/krug.png'); 
    background-repeat: no-repeat;
    background-size: cover;
}

.calc,
.clean{
    width: 170px;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.calc{
    background: #63a949;
}

.clean{
    background: #d15c50;
}

.button__block{
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
